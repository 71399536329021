@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Gotham";
  src: url("../public/assets/fonts/Gotham-Font/GothamThin.otf")
    format("opentype");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Gotham";
  src: url("../public/assets/fonts/Gotham-Font/GothamThinItalic.otf")
    format("opentype");
  font-style: italic;
  font-weight: 100;
}
@font-face {
  font-family: "Gotham";
  src: url("../public/assets/fonts/Gotham-Font/GothamXLight.otf")
    format("opentype");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Gotham";
  src: url("../public/assets/fonts/Gotham-Font/GothamXLightItalic.otf")
    format("opentype");
  font-style: italic;
  font-weight: 200;
}
@font-face {
  font-family: "Gotham";
  src: url("../public/assets/fonts/Gotham-Font/GothamLight.otf")
      format("opentype"),
    url("../public/assets/fonts/Gotham-Font/GothamLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Gotham";
  src: url("../public/assets/fonts/Gotham-Font/GothamLightItalic.ttf")
    format("truetype");
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: "Gotham";
  src: url("../public/assets/fonts/Gotham-Font/GothamBook.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Gotham";
  src: url("../public/assets/fonts/Gotham-Font/GothamBookItalic.ttf")
      format("truetype"),
    url("../public/assets/fonts/Gotham-Font/GothamBookItalic.otf")
      format("opentype");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "Gotham";
  src: url("../public/assets/fonts/Gotham-Font/GothamMedium.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Gotham";
  src: url("../public/assets/fonts/Gotham-Font/GothamMediumItalic.ttf")
    format("truetype");
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: "Gotham";
  src: url("../public/assets/fonts/Gotham-Font/GothamBold.ttf")
      format("truetype"),
    url("../public/assets/fonts/Gotham-Font/GothamBold.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Gotham";
  src: url("../public/assets/fonts/Gotham-Font/GothamBoldItalic.ttf")
    format("truetype");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Jost";
  src: url("../public/assets/fonts/Gotham-Font/JostVariableFont_wght.ttf")
    format("truetype");
}
